import { Component } from "react";
import PropTypes from "prop-types";
import Calendar from "./Calendar";
import BigNumber from "./BigNumber";

import getLanguages from "./createLanguages";
import { makeOldThemeFromNew } from "../ScreenCloudReactApp";
import createCustomStyle from "./utils/createCustomStyle";

class App extends Component {
  constructor(props) {
    super(props);
    this.dateNow = new Date();
    // this.chosenTheme = parsedQuery.theme;

    console.log("props", this.props.theme);
    this.state = {
      date: this.dateNow,
      firstDay: new Date(
        this.dateNow.getFullYear(),
        this.dateNow.getMonth(),
        1
      ),
      lastDay: new Date(
        this.dateNow.getFullYear(),
        this.dateNow.getMonth() + 1,
        0
      ),
      dateNameToday: "",
      dateNameMonth: "",
      firstDayName: "",
      amountOfRows: 0,
      theme: "default",
      startDate: this.props.start_date ? this.props.start_date : "Sunday",
      lang: this.props.lang ? this.props.lang : "en",
    };
  }

  UNSAFE_componentWillMount() {
    if (this.chosenTheme) {
      this.setState({ theme: this.chosenTheme });
    }
    this.setDayNames();
    this.calculateRows();
  }

  setDayNames() {
    this.setState({
      dateNameToday: this.whatDayIsIt(this.state.date),
      firstDayName: this.whatDayIsIt(this.state.firstDay),
      dateNameMonth: this.whatMonthIsIt(this.state.date),
    });
  }

  whatDayIsIt(d) {
    let days = getLanguages(this.props.lang).days;

    return days[d.getDay()];
  }

  whatMonthIsIt(m) {
    let months = getLanguages(this.props.lang).months;
    return months[m.getMonth()];
  }

  calculateRows() {
    console.log(this.state.lastDay);
    const calendarNumbers = [];

    let amountOfEmptyDivs = this.state.firstDay.getDay();
    if (this.startDate !== "Sunday") {
      amountOfEmptyDivs = amountOfEmptyDivs - 1;
    }

    if (amountOfEmptyDivs < 0) {
      amountOfEmptyDivs = 6;
    }
    for (let i = 0; i < amountOfEmptyDivs; i++) {
      calendarNumbers.push(<div key={`empty${i}`} />);
    }
    for (let i = 0; i < this.state.lastDay.getDate(); i++) {
      calendarNumbers.push(
        <div key={`notEmpty${i}`}>{("0" + (i + 1)).slice(-2)}</div>
      );
    }
    this.amountOfRows = Math.ceil(calendarNumbers.length / 7);
  }

  checkIfDayChanged() {
    setInterval(() => {
      const now = new Date();

      if (now.getDay() !== this.state.date.getDay()) {
        this.setState({
          date: now,
        });
        this.setState({
          firstDay: new Date(
            this.state.date.getFullYear(),
            this.state.date.getMonth(),
            1
          ),
          lastDay: new Date(
            this.state.date.getFullYear(),
            this.state.date.getMonth() + 1,
            0
          ),
        });
        this.calculateRows();
        this.setDayNames();
      }
    }, 60000);
  }

  getTheRightOrdinalIndicator(i) {
    const lastone = i.toString().split("").pop();
    let Ordinal = "";
    console.log(lastone);
    switch (lastone) {
      case "1":
        Ordinal = "st";
        break;
      case "2":
        Ordinal = "nd";
        break;
      case "3":
        Ordinal = "rd";
        break;
      default:
        Ordinal = "th";
    }
    return Ordinal;
  }

  componentDidMount() {
    this.checkIfDayChanged();
  }

  render() {
    const { theme } = this.props;
    let customStyle = "";

    if (theme) {
      const oldTheme = makeOldThemeFromNew(this.props.theme);
      customStyle = createCustomStyle(oldTheme);
    }

    if (window.innerWidth * 3.3 < window.innerHeight) {
      return (
        <>
          <style>{customStyle}</style>
          <div className="ErrorMessage color-bg-primary font-heading color-text-heading">
            <p>The &#39;date app&#39; can&#39;t be displayed in this zone</p>
          </div>
        </>
      );
    }
    if (window.innerWidth > window.innerHeight * 5.5) {
      let tickerSpring = "";
      switch (this.state.lang) {
        case "nl":
          tickerSpring = `${
            this.state.dateNameToday
          } ${this.state.date.getDate()} ${this.state.dateNameMonth}`;
          break;
        case "fr":
          tickerSpring = `${
            this.state.dateNameToday
          }, le ${this.state.date.getDate()} ${this.state.dateNameMonth}`;
          break;
        case "de":
          tickerSpring = `${
            this.state.dateNameToday
          }, der ${this.state.date.getDate()} ${this.state.dateNameMonth}`;
          break;
        case "es":
          tickerSpring = `${
            this.state.dateNameToday
          }, ${this.state.date.getDate()} de ${this.state.dateNameMonth}`;
          break;
        default:
          this.state.startDate !== "Sunday"
            ? (tickerSpring = (
                <p>
                  <span>{this.state.dateNameToday}</span> the{" "}
                  {this.state.date.getDate()}
                  <sup>
                    {this.getTheRightOrdinalIndicator(
                      this.state.date.getDate()
                    )}
                  </sup>
                  of {this.state.dateNameMonth}
                </p>
              ))
            : (tickerSpring = (
                <p>
                  <span>{this.state.dateNameToday}</span>
                  <span>{this.state.dateNameMonth}</span>
                  {this.state.date.getDate()}
                </p>
              ));
      }
      return (
        <>
          <style>{customStyle}</style>
          <div className="tickerTape color-bg-primary font-heading color-text-heading">
            {tickerSpring}
          </div>
        </>
      );
    }
    if (this.state.date) {
      return (
        <>
          <style>{customStyle}</style>
          <div
            className={`App rows${this.amountOfRows} font-heading color-bg-primary`}
          >
            <Calendar
              monthName={this.state.dateNameMonth}
              monthStart={this.state.firstDay.getDay()}
              monthLength={this.state.lastDay.getDate()}
              startDay={this.state.startDate}
              today={this.state.date.getDate()}
            />
            <BigNumber
              dateNumber={this.state.date.getDate()}
              dateName={this.state.dateNameToday}
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <style>{customStyle}</style>
          <div className="ErrorMessage color-bg-primary font-heading color-text-heading">
            {getLanguages(this.props.lang).error}
          </div>
        </>
      );
    }
  }
}

App.propTypes = {
  start_date: PropTypes.string,
  lang: PropTypes.string,
  theme: PropTypes.object,
};

export default App;
