let theWordsObject = {};

const getLanguages = (lang) => {
  switch (lang) {
    case "nl":
      theWordsObject = {
        days: [
          "Zondag",
          "Maandag",
          "Dinsdag",
          "Woensdag",
          "Donderdag",
          "Vrijdag",
          "Zaterdag",
        ],
        daysShort: ["zon", "maa", "din", "woe", "don", "vri", "zat"],
        fullTime: "AFGELOPEN",
        halfTime: "RUST",
        months: [
          "Januari",
          "Februari",
          "Maart",
          "April",
          "Mei",
          "Juni",
          "Juli",
          "Augustus",
          "September",
          "Oktober",
          "November",
          "December",
        ],
        monthsShort: [
          "jan",
          "feb",
          "maa",
          "apr",
          "mei",
          "jun",
          "jul",
          "aug",
          "sep",
          "okt",
          "nov",
          "dec",
        ],
        zoneWarning: "App kan niet worden weergegeven in deze zone",
        upcoming: "Volgende",
        today: "Vandaag",
        feelsLike: "Voelt aan als",
        weatherForecast: "Weerbericht",
        month: "maand",
        day: "dag",
        error: "Oeps er ging iets mis",
      };
      break;
    case "fr":
      theWordsObject = {
        days: [
          "Dimanche",
          "Lundi",
          "Mardi",
          "Mercredi",
          "Jeudi",
          "Vendredi",
          "Samedi",
        ],
        daysShort: ["dim", "lun", "mar", "mer", "jeu", "ven", "sam"],
        fullTime: "COMPLÉTER",
        halfTime: "MI-TEMPS",
        months: [
          "Janvier",
          "Février",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Août",
          "Septembre",
          "Octobre",
          "Novembre",
          "Décembre",
        ],
        monthsShort: [
          "jan",
          "fév",
          "mar",
          "avr",
          "mai",
          "juin",
          "juil",
          "aoû",
          "sep",
          "oct",
          "nov",
          "déc",
        ],
        zoneWarning: "App ne peut pas être affichée dans cette zone",
        upcoming: "Prochain",
        today: "Aujourd'hui",
        feelsLike: "Ressentie",
        weatherForecast: "Météo",
        month: "mois",
        day: "jour",
        error: "Oups, quelque chose a mal tourné",
      };
      break;
    case "de":
      theWordsObject = {
        days: [
          "Sonntag",
          "Montag",
          "Dienstag",
          "Mittwoch",
          "Donnerstag",
          "Freitag",
          "Samstag",
        ],
        daysShort: ["son", "mon", "die", "mit", "don", "fre", "sam"],
        fullTime: "FERTIG",
        halfTime: "HALBZEIT",
        months: [
          "Januar",
          "Februar",
          "März",
          "April",
          "Mai",
          "Juni",
          "Juli",
          "August",
          "September",
          "Oktober",
          "November",
          "Dezember",
        ],
        monthsShort: [
          "jan",
          "feb",
          "mär",
          "apr",
          "mai",
          "jun",
          "jul",
          "aug",
          "sep",
          "okt",
          "nov",
          "dez",
        ],
        zoneWarning: "App kann nicht in dieser zone angezeigt werden",
        upcoming: "Kommend",
        today: "Heute",
        feelsLike: "Gefühlt",
        weatherForecast: "Wetterbericht",
        month: "monat",
        day: "tag",
        error: "Oops ist etwas schief gelaufen",
      };
      break;
    case "es":
      theWordsObject = {
        days: [
          "Domingo",
          "Lunes",
          "Martes",
          "Miércoles",
          "Jueves",
          "Viernes",
          "Sábado",
        ],
        daysShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
        fullTime: "DISPUESTO",
        halfTime: "PAUSA",
        months: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        monthsShort: [
          "ene",
          "feb",
          "mar",
          "abr",
          "may",
          "jun",
          "jul",
          "ago",
          "sep",
          "oct",
          "nov",
          "dic",
        ],
        zoneWarning: "La aplicación no puede mostrarse en esta zona",
        upcoming: "Próximo",
        today: "Hoy",
        feelsLike: "Sensación térmica",
        weatherForecast: "pronóstico meteorológico",
        month: "mes",
        day: "día",
        error: "Uy algo salió mal",
      };
      break;
    default:
      theWordsObject = {
        days: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
        daysShort: ["sun", "mon", "tue", "wed", "thu", "fri", "sat"],
        fullTime: "Full Time",
        halfTime: "Half Time",
        months: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        monthsShort: [
          "jan",
          "feb",
          "mar",
          "apr",
          "may",
          "jun",
          "jul",
          "aug",
          "sep",
          "oct",
          "nov",
          "dec",
        ],
        zoneWarning: "App cannot be supported in this zone or layout.",
        upcoming: "Upcoming",
        today: "Today",
        feelsLike: "Feels like",
        weatherForecast: "Weather Forecast",
        month: "month",
        day: "day",
        error: "Oops something went wrong",
      };
  }

  return theWordsObject;
};

export default getLanguages;
