const hexToRgb = (hex, opacity) => {
  var m = hex.match(/^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i);
  var rgb = {
    r: parseInt(m[1], 16),
    g: parseInt(m[2], 16),
    b: parseInt(m[3], 16),
  };

  return "rgba(" + rgb.r + "," + rgb.g + "," + rgb.b + "," + opacity + ")";
};

export default hexToRgb;
