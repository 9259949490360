import PropTypes from "prop-types";

const Calendar = (props) => {
  let calendarNumbers = [];

  const createDivs = () => {
    let amountOfEmptyDivs = props.monthStart;
    if (props.startDay !== "Sunday") {
      console.log(props.startDay);
      amountOfEmptyDivs = amountOfEmptyDivs - 1;
    }

    if (amountOfEmptyDivs < 0) {
      amountOfEmptyDivs = 6;
    }
    console.log(amountOfEmptyDivs);
    for (let i = 0; i < amountOfEmptyDivs; i++) {
      calendarNumbers.push(<div className="numberDiv" key={`empty${i}`} />);
    }
    for (let i = 0; i < props.monthLength; i++) {
      // className can't happen with classnames because an object key doesn't accept '-'
      const dateNumber = ("0" + (i + 1)).slice(-2);
      const theI = i + 1;
      calendarNumbers.push(
        <div key={`notEmpty${i}`} className="numberDiv">
          <div
            // className={classNames('smallNumber', {red: theI === props.today})}>
            className={
              theI === props.today
                ? "color-bg-primary--text smallNumber color-text-heading--bg red"
                : "smallNumber"
            }
          >
            {dateNumber}
          </div>
        </div>
      );
    }
  };

  createDivs();

  return (
    <div className="Calendar">
      <h1>{props.monthName}</h1>
      <div className="calendarNumbers">{calendarNumbers}</div>
    </div>
  );
};

Calendar.propTypes = {
  monthName: PropTypes.string,
  monthStart: PropTypes.number,
  monthLength: PropTypes.number,
  today: PropTypes.number,
  startDay: PropTypes.string,
};
export default Calendar;
