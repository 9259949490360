import PropTypes from "prop-types";

const BigNumber = (props) => {
  return (
    <div className="BigNumber color-bg-primary--text color-text-heading--bg">
      <p>{props.dateName}</p>
      <div className="number">{props.dateNumber}</div>
    </div>
  );
};

BigNumber.propTypes = {
  dateName: PropTypes.string,
  dateNumber: PropTypes.number,
};

export default BigNumber;
