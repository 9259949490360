import "normalize.css"; // Import before any custom CSS. Smoothes out browser differences.
import ReactDOM from "react-dom";
import "whatwg-fetch"; // required until cypress supports fetch API. https://github.com/cypress-io/cypress/issues/95
import ScreenCloudReactApp from "./ScreenCloudReactApp";
import App from "./js/App";
import "./index.css";
import "./css/index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

ReactDOM.render(
  <ScreenCloudReactApp>
    {(sc) => <App sc={sc} {...sc.config} theme={sc.context.theme} />}
  </ScreenCloudReactApp>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

if (window.Cypress) {
  serviceWorkerRegistration.unregister();
} else {
  serviceWorkerRegistration.register();
}
