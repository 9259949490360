import WebFont from "webfontloader";
import colorLuminance from "./colorLuminance";
import hexToRgb from "./hexToRgb";

const extractGoogleFont = (googleFontUrl) => {
  let customGoogleFont = googleFontUrl
    .split("family=")[1]
    .split('" rel=')[0]
    .split(":");
  let customGoogleFontName = customGoogleFont[0].replace(/\+/g, " ");
  return customGoogleFontName;
};

const loadCustomFont = (fontUrl, fontFamily) => {
  if (fontUrl.indexOf("fonts.googleapis.com") !== -1) {
    WebFont.load({
      google: {
        families: [extractGoogleFont(fontUrl)],
      },
    });
  } else {
    WebFont.load({
      custom: {
        families: [fontFamily],
        urls: [fontUrl],
      },
    });
  }
};

const createCustomStyle = (theme_options) => {
  let themeKeys = Object.keys(theme_options) || [];
  if (
    typeof theme_options === "undefined" ||
    !theme_options ||
    theme_options === {} ||
    themeKeys.length === 0
  ) {
    return "";
  }

  let theme = theme_options;
  let fontUrlHeading =
    typeof theme.fontUrlHeading !== "undefined" ? theme.fontUrlHeading : "";
  let fontUrlBody =
    typeof theme.fontUrlBody !== "undefined" ? theme.fontUrlBody : "";
  let fontHeading =
    typeof theme.fontHeading !== "undefined" ? theme.fontHeading : "";
  let fontBody = typeof theme.fontHeading !== "undefined" ? theme.fontBody : "";
  let linkColor =
    typeof theme.colorTextLink !== "undefined" ? theme.colorTextLink : "";
  let linkOpacity;

  if (linkColor !== "") {
    linkOpacity = 1;
  }

  if (fontHeading) {
    loadCustomFont(fontUrlHeading, theme.fontHeading);
  }

  if (fontBody) {
    loadCustomFont(fontUrlBody, theme.fontBody);
  }

  return `
    @font-face {
      font-family: "Custom Heading";
      src: url("${fontUrlHeading}") format("woff2");
    }

    @font-face {
      font-family: "Custom Body";
      src: url("${fontUrlBody}") format("woff2");
    }

    .font-heading {
      font-family: ${fontHeading};
    }

    .font-body {
      font-family: ${fontBody};
    }

    .color-text-link, a {
      color: ${linkColor} !important;
      opacity: ${linkOpacity ? linkOpacity : 1};
    }

    .color-bg-primary {
      background-color: ${theme.colorBgPrimary} !important;
    }

    .color-bg-primary--darker20 {
      background-color: ${colorLuminance(
        theme.colorBgPrimary,
        -0.2
      )} !important;
    }

    .color-bg-primary--text {
      color: ${theme.colorBgPrimary} !important;
    }

    .color-bg-primary--border {
      border-color: ${colorLuminance(theme.colorBgPrimary, -0.2)} !important;
    }

    .color-text-heading--bg-opacity {
      background-color: ${hexToRgb(theme.colorTextHeading, 0.2)} !important;
    }

    .color-text-heading {
      color: ${theme.colorTextHeading} !important;
    }

    .color-text-heading--bg {
      background-color: ${theme.colorTextHeading} !important;
      border-color: ${theme.colorTextHeading} !important;
    }

    .color-text-heading--stroke {
      stroke: ${theme.colorTextHeading} !important;
    }

    .color-text-heading--fill {
      fill: ${theme.colorTextHeading} !important;
    }

    .color-text-heading--border {
      border-color: ${theme.colorTextHeading} !important;
    }

    .color-text-heading--pseudo:before {
      background-color: ${theme.colorTextHeading} !important;
    }

    .color-text-heading--pseudo:after {
      background-color: ${theme.colorTextHeading} !important;
    }

    .color-text-body {
      color: ${theme.colorTextBody} !important;
    }

    .color-text-body--bg {
      background-color: ${theme.colorTextBody} !important;
    }

    .color-text-body--border {
      border-color: ${theme.colorTextBody} !important;
    }

    .color-text-bg {
      color: ${theme.colorBgPrimary} !important;
    }
  `;
};

export default createCustomStyle;
